import Svg, { Path, SvgProps } from 'react-native-svg'

const MatchIcon = ({ fill, ...props }: SvgProps & { fill: string }) => (
	<Svg {...props} viewBox="0 0 16 19">
		<Path
			d="M11.4404 17.399C11.4404 18.0846 11.9414 18.2692 12.4951 17.8649L15.4482 15.7555C15.9052 15.4391 15.8964 14.9469 15.4482 14.6305L12.4863 12.5211C11.9414 12.1256 11.4404 12.319 11.4404 12.9869V14.2877H4.96284C3.50386 14.2877 2.57222 13.4 2.57222 12.1608C2.57222 10.9215 3.48628 10.0162 4.96284 10.0162H10.9833C13.5937 10.0162 15.3251 8.35509 15.3251 6.06115C15.3251 3.72326 13.6025 2.12365 10.9833 2.12365H4.55854V0.814077C4.55854 0.146109 4.05757 -0.0384617 3.51264 0.348257L0.541942 2.45763C0.102489 2.77404 0.0936995 3.26622 0.541942 3.59142L3.50386 5.7008C4.05757 6.0963 4.55854 5.91173 4.55854 5.23497V3.93419H11.1152C12.5742 3.93419 13.5058 4.82189 13.5058 6.06115C13.5058 7.3004 12.5917 8.20568 11.1152 8.20568H5.09468C2.48432 8.20568 0.761668 9.86681 0.761668 12.1608C0.761668 14.4986 2.47554 16.0983 5.09468 16.0983H11.4404V17.399Z"
			fill={fill}
		/>
	</Svg>
)

export default MatchIcon
