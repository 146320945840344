import Svg, { Path, SvgProps } from 'react-native-svg'

const LeaderIcon = ({ fill, ...props }: SvgProps & { fill: string }) => (
	<Svg {...props} viewBox="0 0 24 18">
		<Path
			d="M3.95801 13.1963H20.0684L21.2549 8.35352C21.2637 8.35352 21.2812 8.35352 21.2988 8.35352C22.5205 8.35352 23.5225 7.35156 23.5225 6.12109C23.5225 4.89941 22.5293 3.89746 21.2988 3.89746C20.0684 3.89746 19.0752 4.9082 19.0752 6.12109C19.0752 6.45508 19.1455 6.78027 19.2773 7.06152L16.7285 8.89844C16.4648 9.08301 16.2803 9.02148 16.1396 8.81055L13.1074 4.32812C13.7754 3.94141 14.2324 3.21191 14.2324 2.38574C14.2324 1.16406 13.2305 0.162109 12.0088 0.162109C10.7695 0.162109 9.77637 1.16406 9.77637 2.38574C9.77637 3.21191 10.2334 3.94141 10.9014 4.32812L7.86914 8.81055C7.72852 9.0127 7.57031 9.07422 7.31543 8.88965L4.73145 7.02637C4.85449 6.75391 4.9248 6.44629 4.9248 6.12109C4.9248 4.9082 3.92285 3.89746 2.70117 3.89746C1.4707 3.89746 0.46875 4.89941 0.46875 6.12109C0.46875 7.35156 1.4707 8.35352 2.70117 8.35352C2.71875 8.35352 2.74512 8.35352 2.7627 8.35352L3.95801 13.1963ZM4.29199 14.5938L4.58203 15.7627C4.94238 17.2129 5.7334 17.9512 7.24512 17.9512H16.7725C18.2842 17.9512 19.0752 17.2305 19.4268 15.7627L19.7168 14.5938H4.29199Z"
			fill={fill}
		/>
	</Svg>
)

export default LeaderIcon
