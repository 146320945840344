import Svg, { Path, SvgProps } from 'react-native-svg'

const PointIcon = ({ fill, ...props }: SvgProps & { fill: string }) => (
	<Svg {...props} viewBox="0 0 108.43 103.63">
		<Path
			d="M20.79,102.73a4.08,4.08,0,0,0,3.42.81,10.73,10.73,0,0,0,4.15-2l25.83-19,25.88,19a10.73,10.73,0,0,0,4.15,2,4.08,4.08,0,0,0,3.42-.81,4.51,4.51,0,0,0,1.83-3,9.28,9.28,0,0,0-.61-4.54L78.65,64.79,104.73,46a9.69,9.69,0,0,0,3.17-3.32,4.39,4.39,0,0,0,.29-3.51,4.48,4.48,0,0,0-2.29-2.64,9.7,9.7,0,0,0-4.54-.83l-32,.2L59.66,5.42a10.39,10.39,0,0,0-2.22-4A4.41,4.41,0,0,0,54.19,0,4.34,4.34,0,0,0,51,1.37a10.39,10.39,0,0,0-2.22,4.05L39.05,35.94l-32-.2a9.7,9.7,0,0,0-4.54.83,4.49,4.49,0,0,0-2.3,2.64,4.39,4.39,0,0,0,.3,3.51A9.6,9.6,0,0,0,3.7,46L29.78,64.79,19.57,95.17A9.28,9.28,0,0,0,19,99.71,4.51,4.51,0,0,0,20.79,102.73Z"
			fill={fill}
		/>
	</Svg>
)

export default PointIcon
